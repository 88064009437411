var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-f-h",class:{
        'address-hidden': !_vm.addressVisible,
        'city-hidden': !_vm.cityVisible,
        'client-hidden': !_vm.clientVisible,
        'employees-hidden': !_vm.employeesVisible,
        'services-hidden': !_vm.servicesVisible,
        'note-hidden': !_vm.noteVisible,
        'absence-hidden': !_vm.absenceVisible,
    }},[_c('div',{staticClass:"relative flex w-full justify-center -mt-2 mb-2"},[(_vm.$wait.is('loading.*'))?_c('div',{staticClass:"absolute text-center rounded-full top-0 w-96 h-full bg-green-400 z-20"},[_c('p',{staticClass:"text-white"},[_vm._v(" Loading ")])]):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.selectedMonth.format('MMMM YYYY')))])]),_c('MonthGrid',{attrs:{"eventsData":_vm.eventsData,"redDaysData":_vm.redDaysData,"absenceData":_vm.absenceData},on:{"dateClicked":_vm.showAddModal,"showEditModal":_vm.showEditModal,"refresh":_vm.getAll,"eventDroppedToNewDate":_vm.showDragDropChangeDateModal}}),_c('FloatingMenu',{on:{"goToNextMonth":_vm.nextMonth,"goToPrevMonth":_vm.prevMonth,"goToToday":_vm.goToToday,"monthChange":_vm.monthChange,"changeCalendarSettings":_vm.changeCalendarSettings}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }