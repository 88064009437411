<template>
    <div
        class="card card-f-h"
        :class="{
            'address-hidden': !addressVisible,
            'city-hidden': !cityVisible,
            'client-hidden': !clientVisible,
            'employees-hidden': !employeesVisible,
            'services-hidden': !servicesVisible,
            'note-hidden': !noteVisible,
            'absence-hidden': !absenceVisible,
        }"
    >
        <div class="relative flex w-full justify-center -mt-2 mb-2">
            <div v-if="$wait.is('loading.*')" class="absolute text-center rounded-full top-0 w-96 h-full bg-green-400 z-20">
                <p class="text-white">
                    Loading
                </p>
            </div>
            <p>{{ selectedMonth.format('MMMM YYYY') }}</p>
        </div>

        <MonthGrid
            :eventsData="eventsData"
            :redDaysData="redDaysData"
            :absenceData="absenceData"
            @dateClicked="showAddModal"
            @showEditModal="showEditModal"
            @refresh="getAll"
            @eventDroppedToNewDate="showDragDropChangeDateModal"
        />

        <FloatingMenu
            @goToNextMonth="nextMonth"
            @goToPrevMonth="prevMonth"
            @goToToday="goToToday"
            @monthChange="monthChange"
            @changeCalendarSettings="changeCalendarSettings"
        />
    </div>
</template>
<script>
import queryString from 'query-string';

export default {
    components: {
        MonthGrid:    () => import('./components/MonthGrid.vue'),
        FloatingMenu: () => import(/* webpackChunkName: "components/MonthFloatingMenu" */ './components/MonthFloatingMenu'),
    },

    data() {
        return {
            eventsData:        [],
            redDaysData:       {},
            absenceData:       [],
            editModalVisible:  false,
            selectedEventUuid: null,
            selectedMonth:     this.$dayjs(),
            addressVisible:    window.Cookies.get('monthNew_address_visible') !== 'false',
            cityVisible:       window.Cookies.get('monthNew_city_visible') !== 'false',
            clientVisible:     window.Cookies.get('monthNew_client_visible') !== 'false',
            employeesVisible:  window.Cookies.get('monthNew_employees_visible') !== 'false',
            servicesVisible:   window.Cookies.get('monthNew_services_visible') !== 'false',
            noteVisible:       window.Cookies.get('monthNew_note_visible') !== 'false',
            absenceVisible:    window.Cookies.get('monthNew_absence_visible') !== 'false',
        };
    },

    computed: {
        calendarRefreshIndex() {
            return this.$store.state.calendar.calendarRefreshIndex;
        },
    },

    watch: {
        calendarRefreshIndex() {
            this.getAll();
            this.getAllAbsence();
        },
    },

    created() {
        this.getAll();
        this.getRedDays();
        this.getAllAbsence();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading.init');

            const query = queryString.parse(window.location.search);

            // Update selected date for Filter to update total assigned hours
            this.$store.commit('calendar/updateCurrentDate', this.selectedMonth.unix());

            try {
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/calendar/new_month_calendar`, {
                    requestId: 'axiosRequest',
                    params:    {
                        selectedMonth:          this.selectedMonth.format('D.M.YYYY'),
                        clients_uuid:           query.client ? [query.client].flat() : [],
                        employees_uuid:         query.employee ? [query.employee].flat() : [],
                        services:          query.services ? [query.services].flat() : [],
                        clients_manager_uuid:   query.clients_manager_uuid ? [query.clients_manager_uuid].flat() : [],
                        employees_manager_uuid: query.employees_manager_uuid ? [query.employees_manager_uuid].flat() : [],
                        without_employees:      query.without_employees || 0,
                        cancelled:              query.cancelled || 0,
                        with_keys_only:         query.only_with_keys || 0,
                    },
                });

                this.eventsData = Object.freeze(data);
            } catch (error) {
                console.log('Request cancelled');
            }
            this.$wait.end('loading.prev');
            this.$wait.end('loading.next');
            this.$wait.end('loading.home');
            this.$wait.end('loading.calendar');
            this.$wait.end('loading.init');
        },

        async getAllAbsence() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/calendar/employees_absence`, {
                requestId: 'axiosRequest2',
                params:    {
                    selectedMonth: this.selectedMonth.format('D.M.YYYY'),
                },
            });

            this.absenceData = data;
        },

        async getRedDays() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/calendar/red_days`);
            this.redDaysData = data;
        },

        showDragDropChangeDateModal(uuid, oldDate, newDate) {
            this.$store.commit('calendar/showModalDragDropChangeDateVisible', {
                uuid,
                oldDate,
                newDate,
            });
        },

        showEditModal(uuid) {
            this.$store.commit('calendar/showEditNewModal', uuid);
        },

        showAddModal(date) {
            this.$store.commit('calendar/showAddNewModal', {
                startDateSelected: this.$dayjs(date, 'DD.MM.YYYY'),
            });
        },

        nextMonth() {
            this.$wait.start('loading.next');
            this.selectedMonth = this.selectedMonth.add(1, 'month');
            this.getAll();
        },

        prevMonth() {
            this.$wait.start('loading.prev');
            this.selectedMonth = this.selectedMonth.subtract(1, 'month');
            this.getAll();
        },

        goToToday() {
            this.$wait.start('loading.home');
            this.selectedMonth = this.$dayjs();
            this.getAll();
        },

        monthChange(evt) {
            this.$wait.start('loading.calendar');
            this.selectedMonth = this.$dayjs(evt);
            this.getAll();
        },

        changeCalendarSettings(event) {
            this.addressVisible = event.addressVisible;
            this.cityVisible = event.cityVisible;
            this.clientVisible = event.clientVisible;
            this.employeesVisible = event.employeesVisible;
            this.servicesVisible = event.servicesVisible;
            this.noteVisible = event.noteVisible;
            this.absenceVisible = event.absenceVisible;
        },
    },
};
</script>
